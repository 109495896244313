import React from "react"
import FormLogo from "../../../images/landing-pages/form-logo.svg"

class PaidDigitalForm extends React.Component {
  render() {
    const submitBtnText = this.props.submitBtnText ?? "Submit"
    return (
      <div className="nd-landing-form">
        <div className="form-wrap">
          <div className="form-title">
            <img src={FormLogo} alt="" />
          </div>
          <form
            name="paid-digital-media-performance-benchmark"
            method="post"
            action="/thankyou-paid-digital"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <div className="form-body">
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="paid-digital-media-performance-benchmark" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </p>
              <p className="field">
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  required
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  onChange={this.handleChange}
                />
              </p>
              <p className="field">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  required
                  name="company"
                  id="company"
                  placeholder="Enter your company"
                  onChange={this.handleChange}
                />
              </p>
              <p className="field">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  required
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  onChange={this.handleChange}
                />
              </p>
              <p className="field">
                <label htmlFor="phone">Phone</label>
                <input
                  type="phone"
                  required
                  name="phone"
                  id="phone"
                  placeholder="Enter your phone"
                  onChange={this.handleChange}
                />
              </p>
            </div>

            <div className="form-footer">
              <div className="action-btn">
                <button type="submit">{submitBtnText}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default PaidDigitalForm
