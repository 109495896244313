import React from "react"
import WtnImg from "../../images/landing-pages/want-to-know-img.png"
import PaidDigitalForm from "./blocks/paid-digital-form"

class WantToKnowSection extends React.Component {
  render() {
    return (
      <section id="nd-paid-digital-form-ss" className="wtn-ss">
        <div className="ld-container">
          <div className="wtn-wrap">
            <h2>Want to know how your digital media is performing?</h2>

            <div className="wtn-content">
              <div className="left-col">
                <img src={WtnImg} alt="" />
              </div>

              <div className="right-col">
                <PaidDigitalForm submitBtnText="Measure ad performance" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WantToKnowSection
