import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/landing-pages/blocks/header.js"
import HeroBlueSection from "../components/landing-pages/hero-blue-section.js"
import FeaturedSection from "../components/landing-pages/featured-section.js"
import TransparencySection from "../components/landing-pages/transparency-section.js"
import PrometheusSection from "../components/landing-pages/prometheus-section.js"
import AgencySection from "../components/landing-pages/agency-section.js"
import FAQSection from "../components/landing-pages/faq-section.js"
import WantToKnowSection from "../components/landing-pages/want-to-know-section.js"
import "../scss/landing.scss"

export default function PaidDigitalPage({ data }) {
  const metadescription =
    "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950"
  const metatitle = "Paid Digital Media Performance Benchmark - Next&Co"
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  const faqs = [
    {
      question: "Why do you do these benchmark reports?",
      answer:
        "We believe that advertisers should be armed with an understanding of how their digital media is performing.  As an agency, this is information that we can readily provide to start a relationship.  That’s incentive enough for us!",
    },
    {
      question: "How long does the process take?",
      answer:
        "An report can take anywhere from 2 to 8 weeks depending on how complex your current media buying ecosystem is.",
    },
    {
      question: "What happens after the report is finished?",
      answer:
        "After the report is completed it is all about implementing the recommendations.  This is something that can be done by Next&Co under our media management services or can be passed on to be done by your existing agency / internal team.",
    },
    {
      question: "What services does Next&Co provide?",
      answer:
        "We are a full-service, performance led digital marketing and media agency.  A full list of our capabilities can be found here: <a href='https://www.nextandco.com.au/capabilities'>https://www.nextandco.com.au/capabilities</a>",
    },
    {
      question: "Does the audit cost anything?",
      answer:
        "We do have a cost for the audit that is quoted once we assess the complexity of your media ecosystem.  This cost is credited back against any future costs of media management should you get Next&Co onboard for media services post-audit.",
    },
  ]

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>

      <div id="nd-ld-page" className="nc-landing-page blue">
        <Header />

        <HeroBlueSection />
        <FeaturedSection />
        <TransparencySection />
        <PrometheusSection />
        <AgencySection />
        <FAQSection faqs={faqs} />
        <WantToKnowSection />
      </div>
    </>
  )
}
