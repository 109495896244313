import React from "react"
import AgencyImg from "../../images/landing-pages/agency-ss-img.png"

class AgencySection extends React.Component {
  render() {
    return (
      <section className="agency-ss">
        <div className="ld-container">
          <div className="agency-wrap">
            <div className="left-col">
              <h2>The agency behind Prometheus™</h2>
              <div className="description">
                <p>
                  Founded in 2010, Next&Co is a performance marketing & media
                  agency that exist to make the digital world Measurably
                  Better™. Our goal is to work with our clients to define what
                  'better' looks like and how it can be measured in a digital
                  environment.
                </p>
                <p>
                  More sales, enquiries, downloads, or any other form of
                  engagement - we take accountability for measuring the outcomes
                  of our work and the pursuit of continuous improvement.
                </p>
              </div>
            </div>

            <div className="right-col">
              <div className="agency-img">
                <img src={AgencyImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default AgencySection
