import React from "react"
import FeaturedImg from "../../images/landing-pages/feature-ss-img.png"
import AddNews from "../../images/landing-pages/ad-news.png"
import MediaWeek from "../../images/landing-pages/media-week.png"
import FinancialReview from "../../images/landing-pages/financial-review.png"
import BtImg from "../../images/landing-pages/bt.png"
import Umbrella from "../../images/landing-pages/umbrella.png"
import Campaign from "../../images/landing-pages/campaign.png"

class FeaturedSection extends React.Component {
  render() {
    return (
      <section className="featured-ss">
        <div className="ld-container">
          <div className="featured-wrap">
            <div className="left-col">
              <div className="heading">
                <h3 className="special">As featured in</h3>
              </div>
              <div className="featured-img-lst">
                <div>
                  <img src={AddNews} alt="" />
                  <img src={MediaWeek} alt="" />
                </div>
                <div>
                  <img src={FinancialReview} alt="" />
                  <img src={BtImg} alt="" />
                </div>
                <div>
                  <img src={Umbrella} alt="" />
                  <img src={Campaign} alt="" />
                </div>
              </div>
            </div>

            <div className="right-col">
              <div>
                <img src={FeaturedImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FeaturedSection
