import React from "react"
import TransparencyImg from "../../images/landing-pages/transparency-ss-img.png"
import TransparencyMobileImg from "../../images/landing-pages/transparency-ss-mobile-img.png"

class TransparencySection extends React.Component {
  render() {
    return (
      <section className="transparency-ss">
        <div className="ld-container">
          <div className="transparency-wrap">
            <div className="heading">
              <h2>Transparency to Help Maximize Your Ad Spend</h2>
            </div>
            <div className="description">
              <p>
                We believe every organisation deserves access to accurate,
                transparent information about the efficacy of their advertising.
              </p>
              <p>
                Prometheus™ is Next&Co’s proprietary tool used in-house to
                minimise media waste & find scale for our clients that is being
                leveraged to provide clarity to brands and advertisers.
              </p>
            </div>

            <div className="transparency-img">
              <img className="desktop" src={TransparencyImg} alt="" />
              <img className="mobile" src={TransparencyMobileImg} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TransparencySection
